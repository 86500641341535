<template>
  <b-card>
    <b-row class="row">
      <!-- Employee Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Employee Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar size="100" :text="avatarText(employeeData.fullName)" v-if="employeeData.avatar === ''"/>
          <b-avatar size="100" v-bind:src="'data:image/jpeg;base64,'+employeeData.avatar" v-else/>
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ employeeData.fullName }}
              </h4>
              <span class="card-text">{{ employeeData.internalEmail }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                v-if="restrictions('button_edit_employee')"
                @click="
                  $router.push({
                    name: 'apps-employees-edit',
                    params: { id: employeeData.id },
                  })
                "
                variant="primary"
              >
                {{ $t("message.employees.edit") }}
              </b-button>
              <b-button
                v-b-modal.modal-delete-confirmation
                v-if="restrictions('button_profile_employee')"
                variant="warning"
                class="ml-1"
                @click="
                  $router.push({
                    name: 'apps-user-profile',
                    params: { id: userId },
                  })
                "
              >
                {{ $t("message.employees.profile") }}
                <feather-icon icon="UserIcon" />
              </b-button>
              <b-button
                v-b-modal.modal-delete-confirmation
                v-if="restrictions('button_delete_employee')"
                variant="outline-danger"
                class="ml-1"
              >
                {{ $t("message.employees.delet") }}
              </b-button>
            </div>
          </div>
        </div>
        <b-modal
          id="modal-delete-confirmation"
          modal-class="modal-danger"
          centered
          :title="$t('delete')"
          hide-footer
        >
          <b-card-text>
            {{ $t("message.employees.deleteEmployee") }}
            <b>{{ employeeData.fullName }}</b> ?
          </b-card-text>
          <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <b-button
              v-b-modal.modal-danger
              class="mt-2"
              variant="danger"
              :to="{ name: 'apps-employees-list' }"
              @click="deleteEmployee()"
            >
              {{ $t("message.employees.delet") }}
            </b-button>
          </div>
        </b-modal>

        <!-- Employee Stats -->
        <div class="d-flex align-items-center mt-2">
          <div class="d-flex align-items-center mr-2">
            <b-avatar variant="light-primary" rounded>
              <feather-icon icon="ClipboardIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ employeeData.type || "Não informado" }}
              </h5>
              <small> {{ $t("message.employees.contract") }}</small>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <b-avatar variant="light-success" rounded>
              <feather-icon icon="ToolIcon" size="18" />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ employeeData.projectRole || "Não informado" }}
              </h5>
              <small> {{ $t("message.employees.function") }}</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col cols="12" xl="6">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th>
              <feather-icon icon="CheckIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t("message.employees.status.label")
              }}</span>
            </th>
            <td v-if="employeeData.active">{{$t("message.employees.status.active")}}</td>
            <td v-else>{{$t("message.employees.status.inactive")}}</td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="AwardIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t("message.employees.teams")
              }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ teamsByMember.join(", ") || "Não informado" }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="ClockIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t("message.employees.hoursReleased")
              }}</span>
            </th>
            <td class="pb-50">
              {{ statisticList.timesheet + "h" || "Não informado" }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="MailIcon" class="mr-75" />
              <span class="font-weight-bold">{{
                $t("message.employees.corporateEmail")
              }}</span>
            </th>
            <td class="pb-50">
              {{ employeeData.internalEmail || "Não informado" }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="GithubIcon" class="mr-75" />
              <span class="font-weight-bold">GitHub</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ employeeData.git || "Não informado" }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon icon="LinkedinIcon" class="mr-75" />
              <span class="font-weight-bold">LinkedIn</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ employeeData.linkedin || "Não informado" }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BButton, BAvatar, BRow, BCol, BCardText } from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import router from "@/router";
import getHeader from "@core/utils/auth";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { avatarText } from "@core/utils/filter";
import AccessControl from "@core/utils/access-control";
import store from "@/store";

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BCardText,
  },

  data() {
    return {
      size: 15,
      //select
      employeeData: {},
      avatarText,

      userData: store.state.user.userData,

      statisticList: [],
      userId: "",
      teamsByMember: [],
    };
  },

  async created() {
    await axios
      .get(`${URL_API}user/employeeId/${router.currentRoute.params.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        this.userId = response.data;
      });

    await axios({
      method: "get",
      url: `${URL_API}employee/${router.currentRoute.params.id}`,
      headers: getHeader(this.userData),
    }).then((response) => {
      console.log(response.data);
      this.employeeData = response.data;
    });

    await axios
      .get(`${URL_API}dashboard/statistic/${this.userId}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        this.statisticList = response.data;
      });

    await axios
      .get(`${URL_API}team/teamsByMember/${this.userId}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.content.length; i++) {
          this.teamsByMember.push(response.data.content[i].name);
        }
      });
  },

  methods: {
    restrictions(value) {
      return AccessControl(value);
    },

    deleteEmployee() {
      axios({
        method: "delete",
        url: `${URL_API}employee/${this.employeeData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `O funcionário ${this.employeeData.fullName} foi deletado com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            employeeData.value = undefined;
          }
        });
    },
  },
};
</script>

<style>
.row {
  padding-right: 1.5rem;
}
</style>
