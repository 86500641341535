<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="employeeData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Funcionário não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-employees-list' }">
          Lista de funcionários
        </b-link>
        for other employees.
      </div>
    </b-alert>

    <template v-if="employeeData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12" xl="12" lg="8" md="7">
          <employee-view-employee-info-card :employee-data="employeeData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="12">
          <employee-summary-info :employee-data="employeeData" />
        </b-col>
      </b-row>

      <!-- Spacer -->
      <hr class="invoice-spacing" />

      <b-form-group label-for="wildcard">
        <template v-slot:label> {{ $t("uploadFile") }} </template>
        <b-form-file
          style="width: 350px"
          id="wildcard"
ref="file-input"
          class="mr-1"
          v-model="file"
          browse-text="Procurar"
          placeholder="Nenhum arquivo selecionado"
          drop-placeholder="Arraste arquivo aqui"
          :accept="fileFormats"
        >
        </b-form-file>
        <b-button
          style="margin-top: 7px"
          variant="warning"
          class="btn-icon mr-1"
          @click="file = null"
          v-if="file"
        >
        {{ $t("clean") }}  <feather-icon icon="RefreshCwIcon" />
        </b-button>
        <b-button
          style="margin-top: 7px"
          variant="success"
          class="btn-icon"
          @click="addFile()"
          v-if="file"
        >
        {{ $t("createFile") }}  <feather-icon icon="SendIcon" />
        </b-button>
      </b-form-group>

      <br />

      <b-row>
        <b-col
          style="max-width: 200px; !important"
          v-for="item in filesData"
          :key="item.id"
        >
          <b-card text-variant="white" class="text-center" bg-variant="primary">
            <feather-icon size="40" icon="FileIcon" />

            <div class="truncate">
              <br />
              <span>{{ item.name }}</span>
            </div>
            <br />
            <b-row>
              <b-col md="6"
                ><b-button size="sm" @click="downloadFile(item.id, item.name)" v-if="restrictions('employee_file_download')"
                  ><feather-icon size="20" icon="DownloadCloudIcon"
                /></b-button>
              </b-col>

              <b-col md="6"
                ><b-button
                  v-if="restrictions('employee_file_delete')"
                  size="sm"
                  v-b-modal.modal-delete-file-confirmation
                  @click="
                    selectedFileId = item.id;
                    selectedFileName = item.name;
                  "
                  ><feather-icon size="20" icon="XIcon"
                /></b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <b-modal
        id="modal-delete-file-confirmation"
        modal-class="modal-danger"
        centered
        title="Deletar"
        hide-footer
      >
        <b-card-text>
          Você tem certeza que deseja deletar o arquivo
          <b>{{ selectedFileName }}</b> ?</b-card-text
        >
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            @click="deleteFile()"
          >
            Deletar
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BFormFile,
  BFormGroup,
  BModal,
  BButton,
  BCard,
  BCardBody,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import EmployeeViewEmployeeInfoCard from "./EmployeeViewEmployeeInfoCard.vue";
import EmployeeViewEmployeePlanCard from "./EmployeeViewEmployeePlanCard.vue";
import EmployeeSummaryInfo from "./EmployeeSummaryInfo.vue";
import store from "@/store";
import getHeaderFile from "@core/utils/authFile";
import download from "downloadjs";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccessControl from "@core/utils/access-control";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BFormFile,
    BFormGroup,
    BModal,
    BButton,
    BCard,
    BCardBody,

    // Local Components
    EmployeeViewEmployeeInfoCard,
    EmployeeViewEmployeePlanCard,
    EmployeeSummaryInfo,
  },

  data() {
    return {
      //select
      employeeData: {},

      userData: store.state.user.userData,
      paramData: store.state.user.paramData,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",
    };
  },

  watch: {
    file: function (newVal, oldVal) {
      if (newVal != null) {
        const maxAllowedSize = this.fileMaxSize * 1024 * 1024;
        if (newVal.size > maxAllowedSize) {
          // Here you can ask your users to load correct file
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Não permitido",
              icon: "XIcon",
              variant: "danger",
              text: "Arquivo com tamanho maior do que permitido pelo sistema",
            },
          });
          this.$refs['file-input'].reset()
        }
      }
    },
  },

  async created() {
    await axios({
      method: "get",
      url: `${URL_API}employee/${router.currentRoute.params.id}`,
      headers: getHeader(this.userData),
    }).then((response) => {
      this.employeeData = response.data;
    });

    await this.getEmployeeFilesData();
    this.loadFileFormats();
  },

  methods: {
    async getEmployeeFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    loadFileFormats() {
      this.fileFormats = this.paramData.fileTypes.join(", ").toString();
      this.fileMaxSize = this.paramData.maxFileSize;
    },

    restrictions(value) {
      return AccessControl(value);
    },     

    addFile() {
      let formData = new FormData();
      formData.append("file", this.file);

      axios
        .post(
          `${URL_API}file/employee/${this.userData.id}/${router.currentRoute.params.id}`,
          formData,
          {
            headers: getHeaderFile(this.userData),
          }
        )
        .then((response) => {
          if (!response.data.id && response.data != "") {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("noDataAvailable"),
                icon: "XIcon",
                variant: "danger",
                text: this.$i18n.t("noDataAvailableDesc"),
              },
            });
          } else if(response.data === "") {
            this.getEmployeeFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          }else {
            this.getEmployeeFilesData();
            this.file = null;
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("message.toast.create"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$i18n.t("message.toast.create"),
              },
            });
          }
        });        
    },

    deleteFile() {
      axios({
        method: "delete",
        url: `${URL_API}file/${this.selectedFileId}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        if (response.data === "") {
          this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
        } else {
        this.getEmployeeFilesData();
        this.$root.$emit("bv::hide::modal", "modal-delete-file-confirmation");
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Deletado com sucesso!",
            icon: "CheckIcon",
            variant: "success",
            text: "Arquivo deletado com sucesso",
          },
        });
      }
      });
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          } else {
        const content = response.headers["content-type"];
        download(response.data, name, content);
        }
      });
    },
  },
};
</script>

<style></style>
