<template>
  <div>
    <b-container
      id="b-container"
      class="d-flex justify-content-between flex-column"
    >
      <b-row class="form2">
        <!-- card collapsible  -->
        <!-- mirror personal information  -->
        <b-col v-model="visible" md="12" class="mb-0 pl-0">
          <b-card-actions title="Informações pessoais" action-collapse>
            <b-form>
              <div class="info-person">
                <b-row class="mt-2 d-flex justify-content-center">
                  <b-col md="2">
                    <div class="ml-1" style="text-align: center">
                      <feather-icon icon="UserIcon" class="mr-80" />
                      <span class="font-weight-bold">
                        {{ $t("message.employees.fullname") }}</span
                      >
                      <b-form-group
                        style="text-align: center"
                        label-for="name"
                        class="mt-1"
                        >{{ employeeData.fullName || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3" style="text-align: center">
                      <feather-icon icon="MailIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.personalEmail")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="personalEmail"
                        class="mt-1"
                        >{{ employeeData.internalEmail || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3" style="text-align: center">
                      <feather-icon icon="CalendarIcon" class="mr-80" />
                      <span class="font-weight-bold">
                        {{ $t("message.employees.birthDate") }}
                      </span>
                      <b-form-group
                        style="text-align: center"
                        class="mt-1"
                        type="dateTime"
                      >
                        <a v-if="employeeData.dateBirth">
                          {{
                            $d(
                              new Date(employeeData.dateBirth),
                              "short",
                              locale
                            )
                          }}
                        </a>
                        <a v-else>
                          {{ "Não informado" }}
                        </a>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-1 mt-0" style="text-align: center">
                      <div class="mx-auto">
                        <feather-icon icon="FlagIcon" class="mr-80" />
                        <span class="font-weight-bold">{{
                          $t("message.employees.cpf")
                        }}</span>
                      </div>
                      <b-form-group
                        style="text-align: center"
                        label-for="cpf"
                        class="mt-1"
                        >{{ employeeData.cpf || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <!--  -->
                <b-row class="mt-3 d-flex justify-content-center">
                  <b-col md="2">
                    <div class="ml-1 mt-1" style="text-align: center">
                      <feather-icon icon="SmartphoneIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.cellPhone")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="mobilePhone"
                        class="mt-1"
                        >{{ employeeData.mobilePhoneNumber || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3 mt-1" style="text-align: center">
                      <feather-icon icon="HomeIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.homeAddress")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="mobilePhone"
                        class="mt-1"
                      >
                        {{ employeeData.address || "Não informado" }} -
                        {{ employeeData.district || "Não informado" }} -
                        {{ employeeData.city || "Não informado" }} -
                        {{ employeeData.uf || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-0 mt-1" style="text-align: center">
                      <feather-icon icon="PhoneIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.phone")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="mobilePhone"
                        class="mt-1"
                        >{{ employeeData.phoneNumber || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-1 mt-1" style="text-align: center">
                      <feather-icon icon="UsersIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.maritalStatus")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="maritalStatus"
                        class="mt-1"
                        >{{ employeeData.maritalStatus || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-3 d-flex justify-content-center">
                  <b-col md="2">
                    <div class="ml-0 mt-1" style="text-align: center">
                      <feather-icon icon="UsersIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.gender")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="sex"
                        class="mt-1"
                        >{{ employeeData.sex || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3 mt-1" style="text-align: center">
                      <feather-icon icon="UsersIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.sexualOrientation")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="sexualOrientation"
                        class="mt-1"
                        >{{ employeeData.sexualOrientation || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-1 mt-1" style="text-align: center">
                      <feather-icon icon="UserIcon" class="mr-80" />
                      <span class="font-weight-bold">
                        {{ $t("message.employees.mothersName") }}</span
                      >
                      <b-form-group
                        style="text-align: center"
                        label-for="motherName"
                        class="mt-1"
                        >{{ employeeData.motherName || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3 mt-1" style="text-align: center">
                      <feather-icon icon="UserIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.fathersName")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="fatherName"
                        class="mt-1"
                        >{{ employeeData.fatherName || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mt-3 d-flex justify-content-center">
                  <b-col md="2">
                    <div class="ml-3 mt-1" style="text-align: center">
                      <feather-icon icon="FlagIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.naturalness")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="birthPlace"
                        class="mt-1"
                        >{{ employeeData.birthPlace || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col md="3">
                    <div class="ml-2 mt-1" style="text-align: center">
                      <feather-icon icon="FlagIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.state")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="birthPlaceState"
                        class="mt-1"
                        >{{ employeeData.birthPlaceState || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col md="3" style="text-align: center">
                    <div class="ml-2 mt-1">
                      <feather-icon icon="FlagIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.nationality")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="nationality"
                        class="mt-1"
                        >{{ employeeData.nationality || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>

                  <b-col md="3" style="text-align: center">
                    <div class="ml-3 mt-1">
                      <feather-icon icon="FlagIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.rg")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="identityId"
                        class="mt-1"
                        >{{ employeeData.identityId || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <!-- fim das informaçõs pessoais -->

              <h4 class="mt-3 mb-2 pl-30">Informações de contrato</h4>
              <div class="info-profissionals">
                <b-row class="mt-3 d-flex justify-content-center mt-3">
                  <b-col md="2">
                    <div class="ml-1" style="text-align: center">
                      <feather-icon icon="CalendarIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.admission")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="startDate"
                        class="mt-1"
                      >
                        <a v-if="employeeData.date">
                          {{ $d(new Date(employeeData.date), "short", locale) }}
                        </a>
                        <a v-else>
                          {{ "Não informado" }}
                        </a>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3" style="text-align: center">
                      <feather-icon icon="CalendarIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.medicalExaminationDate")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="medicalExamDate"
                        class="mt-1"
                      >
                        <a v-if="employeeData.medicalExamDate">
                          {{
                            $d(
                              new Date(employeeData.medicalExamDate),
                              "short",
                              locale
                            )
                          }}
                        </a>
                        <a v-else>
                          {{ "Não informado" }}
                        </a>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3" style="text-align: center">
                      <feather-icon icon="CalendarIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.locale")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="medicalExamPlace"
                        class="mt-1"
                        >{{ employeeData.medicalExamPlace || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-1" style="text-align: center">
                      <feather-icon icon="DollarSignIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.register")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="registerNumber"
                        class="mt-1"
                        >{{ employeeData.registerNumber || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-3 d-flex justify-content-center mt-3">
                  <b-col md="2">
                    <div class="ml-1" style="text-align: center">
                      <feather-icon icon="DollarSignIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.contract")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="admissionTitle"
                        class="mt-1"
                        >{{ employeeData.type || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-1" style="text-align: center">
                      <feather-icon icon="DollarSignIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.salary")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="salary"
                        class="mt-1"
                      >
                        <a v-if="employeeData.salary">
                          {{ $n(employeeData.salary, "currency") }}
                        </a>
                        <a v-else>
                          {{ "Não informado" }}
                        </a>
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3" style="text-align: center">
                      <feather-icon icon="DollarSignIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.paymentMethod")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="paymentType"
                        class="mt-1"
                        >{{ employeeData.paymentType || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3" style="text-align: center">
                      <feather-icon icon="FlagIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.e-SocialEnrollment")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="identityId"
                        class="mt-1"
                        >{{ employeeData.socialNumber || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-3 d-flex justify-content-center mt-3">
                  <b-col md="2">
                    <div class="ml-1" style="text-align: center">
                      <feather-icon icon="FlagIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.department")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="department"
                        class="mt-1"
                        >{{ employeeData.department || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3" style="text-align: center">
                      <feather-icon icon="FlagIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.sector")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="sector"
                        class="mt-1"
                        >{{ employeeData.sector || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3" style="text-align: center">
                      <feather-icon icon="FlagIcon" class="mr-80" />
                      <span class="font-weight-bold">{{
                        $t("message.employees.section")
                      }}</span>
                      <b-form-group
                        style="text-align: center"
                        label-for="session"
                        class="mt-1"
                        >{{ employeeData.session || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                  <b-col md="3">
                    <div class="ml-3" style="text-align: center">
                      <feather-icon icon="FlagIcon" class="mr-80" />
                      <span class="font-weight-bold"
                        >{{ $t("message.employees.workerCategory") }}
                      </span>
                      <b-form-group
                        style="text-align: center"
                        label-for="WorkCategory"
                        class="mt-1"
                        >{{ employeeData.workCategory || "Não informado" }}
                      </b-form-group>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-form>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style>
.ml-n6 {
  margin-left: -6em !important;
}

#b-container {
  max-width: 100%;
}

.info-person,
.info-profissionals {
  background-color: #f9f9f9;
  padding-top: 8px;
  padding-bottom: 20px;
  border-radius: 20px;
}

.form2 {
  margin-right: -1rem;
  margin-left: 0rem;
  padding-right: 0px;
}
#b-container {
  padding-right: 0px;
}

svg {
  margin-right: 5px;
}

.card .card-header .heading-elements {
  display: none;
}

.row {
  margin-left: -1rem;
}
</style>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BCol,
  BCardText,
  BFormGroup,
  BRow,
  BFormInput,
  BContainer,
  BCard,
  BForm,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,
    BCol,
    BCardText,
    BFormGroup,
    BRow,
    BFormInput,
    BContainer,
    BCard,
    BForm,
  },
  props: {
    employeeData: {
      type: Object,
      required: true,
    },
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.locale = newVal;
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.locale = this.$i18n.locale;
    }
  },

  data() {
    return {
      locale: "pt",
      visible: true,
      fields: ["ACTION", { key: "ICON", label: "ICON" }, "DETAILS"],
      items: [
        {
          ACTION: "Collapse",
          ICON: "ChevronDownIcon",
          DETAILS: "Collapse card content using collapse action.",
        },
      ],
    };
  },
};
</script>
