<template>
  <b-row>
    <b-col>
      <b-card no-body>
        <b-card-header
          class="d-flex justify-content-between align-items-center pt-50 pb-0"
        >
          <h4 class="mb-0">{{ $t("message.employees.tasks") }}</h4>
          <b-badge variant="light-primary"> 24 </b-badge>
          <small class="text-muted w-100">{{
            $t("message.employees.associatedProjects")
          }}</small>
        </b-card-header>

        <b-card-body>
          <ul class="list-unstyled my-1">
            <li>
              <span class="align-middle">
                {{ $t("message.employees.finished") }}: <b>5</b>
              </span>
            </li>
            <li class="my-25">
              <span class="align-middle">
                {{ $t("message.employees.inProgress") }}: <b>10</b>
              </span>
            </li>
            <li>
              <span class="align-middle">
                {{ $t("message.employees.pending") }}: <b>9</b></span
              >
            </li>
          </ul>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
          >
            {{ $t("message.employees.toView") }}
          </b-button>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BBadge,
  BButton,
  BCol,
  BRow,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCol,
    BRow,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
};
</script>

<style></style>
